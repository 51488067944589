const cacheOptions = {
  typePolicies: {
    Query: {
      fields: {
        currentUser: { merge: true },
      },
    },
    User: {
      keyFields: ['username'],
    },
    Tag: {
      keyFields: ['slug'],
    },
    ProfileCompany: {
      keyFields: false,
    },
    ProfilePage: {
      keyFields: false,
    },
    Profile: {
      fields: {
        page: { merge: true },
        company: { merge: true },
      },
    },
    JobCompany: {
      keyFields: false,
    },
    Job: {
      fields: {
        company: { merge: true },
      },
    },
  },
} as const;

export default cacheOptions;
