import {
  ApolloClient, InMemoryCache, HttpLink, ApolloLink, from, split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { persistCache } from 'apollo3-cache-persist';
import { createLink } from 'apollo-absinthe-upload-link';
import { RetryLink } from '@apollo/client/link/retry';
import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { Socket as PhoenixSocket } from 'phoenix';

import { enqueueSnackbar } from 'notistack';
import { getProgressSubscriber } from './progress';
import getProtocol from './getProtocol.mjs';
import getOrigin from './getOrigin';
import getApiUrl from './getApiUrl';
import cacheOptions from './cacheOptions';

let apolloClient = null;

const graphCMSLink = new HttpLink({
  uri: 'https://api-eu-central-1.graphcms.com/v2/ck17p5hzt00ti01da4bgu326h/master',
});

export const errorHandler = ({ graphQLErrors, networkError, operation }) => {
  if (typeof window !== 'undefined') {
    const { passErrors = [] } = operation.getContext();

    const filteredGraphQLErrors = graphQLErrors
      ?.filter(({ message }) => !passErrors.includes(message));

    if (filteredGraphQLErrors?.length > 0) {
      if (filteredGraphQLErrors[0].message === 'invalid_token') {
        localStorage.clear();

        window.location.reload();

        return;
      }

      const uniqueMessages = new Set(
        filteredGraphQLErrors
          .map((error) => error.message),
      );

      const errorMessage = [...uniqueMessages].join(', ');

      enqueueSnackbar(errorMessage, { variant: 'error' });

      return;
    }

    if (networkError) {
      enqueueSnackbar(
        `Network error: ${networkError.message}`,
        { variant: 'error' },
      );
    }
  }
};

export const errorLink = onError(errorHandler);

const getAccessToken = () => (typeof localStorage === 'undefined'
  ? null : localStorage.getItem('accessToken'));

export const getSocketParameters = () => ({
  accessToken: getAccessToken(),
});

const getApiSocketUrl = (apiUrl) => apiUrl
  .replace(/^http/, 'ws')
  .replace(/\/\/qa-api\./, '//qa.')
  .replace(/\/\/api\./, '//')
  .replace(/\/graphql\/$/, '/socket');

function create(initialState, options = {}) {
  const { req: request = {}, reqUrl } = options;
  const { headers: requestHeaders, originalUrl } = request;
  const url = reqUrl || (request.get && `${
    getProtocol(request)}://${request.get('host')}${originalUrl}`);
  const apiUrl = getApiUrl(getOrigin(url));
  const httpLink = createLink({
    uri: apiUrl,
    getProgressSubscriber,
  });

  const ipAddressMiddleWare = new ApolloLink((operation, forward) => {
    if (requestHeaders) {
      const {
        'x-real-ip': xRealIp,
        'x-forwarded-for': xForwardedFor,
      } = requestHeaders;

      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'x-real-ip': xRealIp,
          'x-forwarded-for': xForwardedFor,
        },
      }));
    }

    return forward(operation);
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const accessToken = getAccessToken();

    const authorization = accessToken ? `Bearer ${accessToken}` : null;
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization,
      },
    }));
    return forward(operation);
  });

  const apiSplitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    typeof window === 'undefined'
      ? httpLink
      : createAbsintheSocketLink(AbsintheSocket.create(
        new PhoenixSocket(getApiSocketUrl(apiUrl), {
          params: getSocketParameters,
        }),
      )),
    httpLink,
  );

  const apiLink = from([
    errorLink,
    new RetryLink(),
    ipAddressMiddleWare,
    authMiddleware,
    apiSplitLink,
  ]);

  const cache = new InMemoryCache(cacheOptions);

  cache.restore({ ...initialState });

  if (typeof localStorage !== 'undefined') {
    persistCache({
      cache,
      storage: localStorage,
    });
  }

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser,
    link: split(
      (operation) => operation.getContext().source === 'GraphCMS',
      graphCMSLink,
      apiLink,
    ),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      query: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
}

export default function initApollo(initialState, options) {
  if (!process.browser) {
    return create(initialState, options);
  }

  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}
